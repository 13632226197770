<template>
  <section>
    <v-container fluid>
      <v-row>
        <v-col md="12">
          <PageHeader
            :title="headercontent.title"
            :addlink="headercontent.addlink"
          />
        </v-col>
        <v-col md="12">
          <h3 class="filtertitle">Filter {{ headercontent.title }}</h3>
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
            class="form-section"
          >
            <v-row>
              <v-col cols="3">
                <v-select
                  :items="allUser.results"
                  item-text="fullname"
                  item-value="userHandle"
                  v-model="userHandle"
                  label="Employee"
                  outlined
                ></v-select>
              </v-col>
              <v-col cols="12" class="submitbtn">
                <v-btn
                  :disabled="!valid"
                  class="submitbutton btn-dark"
                  @click="validate"
                >
                  Filter {{ headercontent.title }}
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-col>
        <v-col md="12">
          <v-simple-table class="table" v-if="allAttendances.results">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">
                    S/N
                  </th>
                  <th class="text-left">
                    Name
                  </th>
                  <th class="text-left">
                    Start Time
                  </th>
                  <th class="text-left">
                    End Time
                  </th>

                  <th class="text-left">
                    Count
                  </th>
                  <th class="text-left">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item, index) in allAttendances.results.jobs"
                  :key="index"
                >
                  <td>{{ index + 1 }}</td>
                  <td>{{ item.userHandle }}</td>
                  <td>{{ item.startTime | formatDateTime }}</td>
                  <td>{{ item.endTime | formatDateTime }}</td>
                  <td>{{ item.count }}</td>

                  <td class="text-right">
                    <router-link :to="`/dashboard/attendance/add/${item._id}`">
                      <v-icon class="icons-action">
                        mdi-pencil
                      </v-icon></router-link
                    >
                    <!-- <v-icon class="icons-action"> mdi-delete </v-icon>
                    <v-icon class="icons-action"> mdi-download </v-icon> -->
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <ul
            class="pagination-button"
            v-if="allAttendances.results != undefined"
          >
            <li>
              Page :
              <span v-if="allAttendances.results.pagination.next">{{
                (allAttendances.results.pagination.next - 1) * this.limit
              }}</span
              ><span v-if="!allAttendances.results.pagination.next">{{
                allAttendances.results.pagination.total
              }}</span>
              -
              {{ allAttendances.results.pagination.total }}
            </li>
            <li>
              <div>
                <v-btn
                  v-if="allAttendances.results.pagination.previous"
                  @click="prevPage(allAttendances.results.pagination.previous)"
                  class="btn-dark"
                  >Prev</v-btn
                >

                <v-btn
                  v-if="allAttendances.results.pagination.next"
                  @click="nextPage(allAttendances.results.pagination.next)"
                  class="btn-dark"
                  >Next</v-btn
                >
              </div>
            </li>
          </ul>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import PageHeader from "@/components/dashboard/PageHeader";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "Attendance",
  components: { PageHeader },
  data: () => ({
    headercontent: {
      title: "Attendance List",
      addlink: "/dashboard/attendance/add",
    },
    title: "",
    adsposition: "",
    valid: true,
    limit: 20,
    skip: 1,
    userHandle: "",
  }),
  methods: {
    ...mapActions(["fetchAttendance", "getUserForMenu", "filterAttendace"]),
    validate() {
      this.filterAttendace({ userHandle: this.userHandle,limit: this.limit,skip:this.skip });
    },
    nextPage(data) {
      this.filterAttendace({
        userHandle: this.userHandle,
        limit: this.limit,
        skip: data,
      });
    },
    prevPage(data) {
      this.filterAttendace({
        userHandle: this.userHandle,
        limit: this.limit,
        skip: data,
      });
    },
  },
  computed: {
    ...mapGetters(["allAttendances", "allUser"]),
  },
  created() {
    this.getUserForMenu();
    this.fetchAttendance({ limit: this.limit, skip: this.skip });
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
.form-section {
  padding: 15px 0;
  .col {
    padding: 0 10px;
  }
}
.filtertitle {
  margin-bottom: 10px;
}
.btn-dark {
  background: $tagcolor !important;
  color: $white;
  margin-top: -10px;
}

.table {
  box-shadow: 0px -1px 5px 2px rgba(0, 0, 0, 0.1);

  thead {
    border-bottom: 2px solid $menucolor;
    background: $tagcolor;
  }
  th {
    font-size: $normal_lfont !important;
    color: $white !important;
  }
}
.pagination-button {
  display: flex;
  list-style: none;
  padding-left: 0;
  justify-content: space-between;
  box-shadow: 2px 2px 5px 2px rgba(0, 0, 0, 0.1);
  .btn-dark {
    margin-top: 0;
    margin-left: 10px;
  }
}
.table-heading,
.pagination-button {
  border: 1px solid $menucolor;
  margin-bottom: 0;
  padding: 10px;
  align-items: center;
}
.icons-action {
  margin: 10px;
  font-size: $iconfont;
}
</style>
